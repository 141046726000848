var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tree-catalog',_vm._b({},'v-tree-catalog',{
      title: _vm.title,
      tableCellsConfig: _vm.tableCellsConfig,
      metricFilters: _vm.metricFilters,
      page: _vm.pageName,
      apiUrl: '/ecomm'
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }