<template>
  <v-tree-catalog
      v-bind="{
        title,
        tableCellsConfig,
        metricFilters,
        page: pageName,
        apiUrl: '/ecomm'
      }"
  />
</template>

<script>
import {mapState} from 'vuex'
import treeCatalog from '@/services/components/treeCatalog/'

export default {
  components: {
    'v-tree-catalog': treeCatalog
  },

  data() {
    return {
      metricFilters: [
          'product_detail_views',
          'product_adds_to_cart',
          'product_checkouts',
          'item_quantity',
          'item_revenue',
          'merchant_is_marketplace',
          'ws1',
          'ws2'
      ],

      pageName: 'commerceAnalysis',

      title: {
        primary: 'Data analytics',
        secondary: 'Commerce analysis'
      }
    }
  },

  computed: {
    ...mapState({
      tableCellsConfig: state => state.tableSettings.commerceAnalysisTableCellsConfig
    })
  }
}
</script>
